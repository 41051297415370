import React from 'react'

import { List, ListItem, Stack } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Placeholder } from '../../components/Placeholder'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Stack"
    components={[{ component: Stack, restElement: true }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          Elementtien välin voi määritellä vaihtuvaksi eri kokoisilla näytöillä.
        </ListItem>
        <ListItem>
          Jos kyseessä on lista, voi <Code>as</Code> propilla vaihtaa
          juurielementin järjestetyksi (<Code>ol</Code>) tai järjestämättömäksi
          listaksi (<Code>ul</Code>).
        </ListItem>
      </List>
      <Playground>
        <Stack gap={{ xs: 'xxxs', md: 'xs', xl: 'md' }}>
          <Placeholder block />
          <Placeholder block />
          <Placeholder block />
        </Stack>
      </Playground>
    </Section>
  </Content>
)

export default Page
